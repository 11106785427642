import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Session } from "../api";
import { TimerItem } from "../components/TimerItem";
import { Timer } from "../types";

export function Timers() {
  const [timers, setTimers] = useState<Timer[]>([]);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTimers = async () => {
      try {
        const timers = await Session.getTimers();
        setTimers(timers);
      } catch (e) {}
    };
    fetchTimers();
    const interval = setInterval(fetchTimers, 2000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const status = await Session.getStatus();
        setStatus(status.on);
        setLoading(false);
      } catch (e) {}
    };
    fetchStatus();
    const interval = setInterval(fetchStatus, 2000);
    return () => clearInterval(interval);
  }, []);

  const toggleStatus = async () => {
    setLoading(true);
    try {
      const response = await Session.setStatus(!status);
      setStatus(response.on);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-nowrap flex-col items-center justify-center">
      <button
        className={`p-3 text-lg my-3 rounded-lg ${
          status ? "bg-green-400" : "bg-gray-400"
        }`}
        disabled={loading}
        onClick={() => {
          toggleStatus();
        }}
      >
        {status ? "ON" : "OFF"}
      </button>
      <Link className="bg-blue-400 p-3 text-lg my-3 rounded-lg" to="/new">
        Create new +
      </Link>
      <ul className="flex w-full flex-col items-center justify-center bg-pink-200">
        {timers.map((timer) => {
          return <TimerItem key={timer.id} timer={timer} />;
        })}
      </ul>
    </div>
  );
}
