// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import { Redirect, Route, RouteProps } from "react-router-dom";
import { Session } from "../api";

function PrivateRoute(props: RouteProps) {
  // Add your own authentication on the below line.
  const isLoggedIn = Session.isLoggedIn();

  const { children, ...otherProps } = props;
  return (
    <Route {...otherProps}>
      {isLoggedIn ? (
        props.children
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )}
    </Route>
  );
}

export default PrivateRoute;
