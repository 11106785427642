import { PowerStatus, SessionResponse, Timer } from "./types";

const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
};

interface AllowStatusCodes {
  [k: number]: boolean;
}

interface ApiOptions {
  allow: AllowStatusCodes;
}

interface RequestOptions extends Omit<RequestInit, "body"> {
  body?: Record<string, any> | string;
}

export const api = async <T,>(
  input: string,
  init?: RequestOptions | undefined,
  options?: ApiOptions
): Promise<T> => {
  if (init === undefined) {
    init = {};
  }

  init.headers = Object.assign(DEFAULT_HEADERS, init.headers);

  if (init?.body) {
    init.body = JSON.stringify(init.body);
  }
  const url = new URL(input, process.env.REACT_APP_API_URL).href;

  try {
    const token = localStorage.getItem("@auth_token");
    // @ts-ignore
    init.headers["Authorization"] = token ? "Bearer " + token : "";
  } catch (e) {}

  return fetch(url, init as RequestInit).then(async (response) => {
    const data = await response.json();

    if (response.ok || options?.allow[response.status]) {
      return data;
    } else {
      if (response.status === 401) {
        Session.logout();
      }
      return Promise.reject(data);
    }
  });
};

export const Session = {
  login: (email: string, password: string) => {
    return api<SessionResponse>("/user", {
      method: "POST",
      body: { email, password },
    });
  },
  test: () => {
    return api<SessionResponse>("/user");
  },
  updateToken: (token: string | null) => {
    if (token == null) localStorage.removeItem("@auth_token");
    else localStorage.setItem("@auth_token", token);
  },
  isLoggedIn: () => {
    return localStorage.getItem("@auth_token") != null;
  },
  logout: () => {
    Session.updateToken(null);
  },
  getTimers: () => {
    return api<Timer[]>("/timers");
  },
  getTimer: (id: number) => {
    return api<Timer>(`/timers?id=${id}`);
  },
  deleteTimer: (id: number) => {
    return api<{ success: Boolean }>("/timers", {
      method: "DELETE",
      body: { id },
    });
  },
  updateTimer: (timer: Timer) => {
    return api<Timer>("/timers", {
      method: timer.id === 0 ? "PUT" : "PATCH",
      body: timer,
    });
  },
  getStatus: () => {
    return api<PowerStatus>("/status");
  },
  setStatus: (on: Boolean) => {
    return api<PowerStatus>("/status", {
      method: "PATCH",
      body: { on },
    });
  },
};
