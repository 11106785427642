import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Session } from "../api";

export function User() {
  const [user, setUser] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(Session.isLoggedIn());
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await Session.test();
        setUser(user.user.username);
      } catch (e) {
        setIsLoggedIn(Session.isLoggedIn());
      }
    };
    fetchUser();
  }, []);

  return (
    <div>{isLoggedIn ? user : <Redirect to={{ pathname: "/login" }} />}</div>
  );
}
