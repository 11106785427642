import { formatSecondsToTimeInput } from "../helpers";
import { Timer } from "../types";

type TimerItemProps = {
  timer: Timer;
};
export function TimerItem({ timer, ...props }: TimerItemProps) {
  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
    .reduce<string[]>((prev, curr, index, arr) => {
      if ((1 << index) & timer.day_flag) {
        prev.push(curr);
      }
      return prev;
    }, [])
    .join(", ");

  return (
    <li className="flex border-b-2 w-full justify-center">
      <button
        className="w-full"
        onClick={() => {
          window.location.href = `/${timer.id}`;
        }}
      >
        <div className="py-3 ">
          {formatSecondsToTimeInput(timer.from)} -{" "}
          {formatSecondsToTimeInput(timer.to)} - {days} -{" "}
          {timer.active ? "Active" : "Not Active"}
        </div>
      </button>
    </li>
  );
}
