import React, { useState } from "react";
import logo from "../logo.svg";
import "../App.css";
import { Session } from "../api";

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = async () => {
    const response = await Session.login(email, password);
    if (response.token) {
      Session.updateToken(response.token);
      window.location.href = "/";
    }
  };
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <input
          className="text-black"
          type="text"
          placeholder="email"
          value={email}
          onInput={(e) => setEmail(e.currentTarget.value)}
        />
        <input
          className="text-black"
          type="password"
          placeholder="password"
          value={password}
          onInput={(e) => setPassword(e.currentTarget.value)}
        />
        <button onClick={login}>Log in</button>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}
