import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Session } from "../api";
import { formatSecondsToTimeInput } from "../helpers";
import { Timer } from "../types";
interface ParamTypes {
  id?: string;
}

export function TimerEdit() {
  let { id } = useParams<ParamTypes>();
  const timerId = parseInt(id || "0");
  const [timer, setTimer] = useState<Timer | null>(null);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [dayFlag, setDayFlag] = useState(0);
  const [active, setActive] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(timerId > 0);

  useEffect(() => {
    if (timerId > 0) {
      const fetchTimer = async () => {
        try {
          const timer = await Session.getTimer(timerId);
          setTimer(timer);
        } catch (e) {
          setErrorMessage(e.message);
        }
      };

      fetchTimer();
    }
  }, [timerId]);

  useEffect(() => {
    if (timer !== null) {
      setFrom(timer.from);
      setTo(timer.to);
      setDayFlag(timer.day_flag);
      setActive(timer.active);
      setLoading(false);
    }
  }, [timer]);

  const createOrUpdate = async () => {
    try {
      await Session.updateTimer({
        from,
        to,
        day_flag: dayFlag,
        active,
        id: timerId,
      });

      window.location.href = `/`;
    } catch (e) {
      setErrorMessage("ERROR:" + e.message);
    }
  };

  const deleteTimer = async () => {
    try {
      await Session.deleteTimer(timerId);

      window.location.href = `/`;
    } catch (e) {
      setErrorMessage("ERROR:" + e.message);
    }
  };

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  console.log("timer", timer, "id", timerId);
  return (
    <div className="App">
      <div className="flex justify-center flex-wrap bg-blue-100 py-3">
        {errorMessage.length > 0 ? errorMessage : undefined}
        {loading && <div>Loading</div>}
        <input
          type="time"
          placeholder="from"
          value={formatSecondsToTimeInput(from)}
          onInput={(e) => {
            const newValue = e.currentTarget.valueAsNumber / 1000;
            setFrom(newValue);
          }}
          onBlur={() => {
            setTo(Math.max(from + 60, to));
          }}
        />
        <input
          type="time"
          placeholder="to"
          value={formatSecondsToTimeInput(to)}
          onInput={(e) => {
            const newValue = e.currentTarget.valueAsNumber / 1000;
            setTo(newValue);
          }}
          onBlur={() => {
            setFrom(Math.min(to - 60, from));
          }}
        />

        <ul className="inline-flex space-x-1">
          {days.map((day, index) => {
            return (
              <li key={index}>
                <button
                  className={
                    (((1 << index) & dayFlag) !== 0
                      ? "bg-green-400"
                      : "bg-gray-100") + " rounded-lg p-1"
                  }
                  onClick={(e) => {
                    setDayFlag(
                      ((1 << index) & dayFlag) === 0
                        ? (1 << index) | dayFlag
                        : ~(1 << index) & dayFlag
                    );
                  }}
                >
                  {day}
                </button>
              </li>
            );
          })}
        </ul>

        <button
          className={(active ? "bg-green-400" : "bg-gray-100") + " p-1 ml-3"}
          onClick={() => {
            setActive(!active);
          }}
        >
          On
        </button>
      </div>
      {timerId > 0 && (
        <button
          className="bg-red-400 p-3 text-lg mt-3 rounded-lg pl-8 pr-8"
          onClick={() => {
            deleteTimer();
          }}
        >
          Delete
        </button>
      )}
      <button
        className="bg-blue-400 p-3 text-lg mt-3 rounded-lg pl-8 pr-8"
        onClick={createOrUpdate}
      >
        {timerId > 0 ? "Update" : "Create"}
      </button>
    </div>
  );
}
