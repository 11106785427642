import { Redirect } from "react-router-dom";
import { Session } from "../api";
export function Logout() {
  Session.logout();
  console.log("Are we here?");
  return (
    <div>
      <Redirect to={{ pathname: "/login" }} />
    </div>
  );
}
