import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Session } from "./api";
import { BrowserRouter, Link, Route, Router, Switch } from "react-router-dom";
import { Login } from "./screens/Login";
import PrivateRoute from "./screens/PrivateRoute";
import { Logout } from "./screens/Logout";
import { User } from "./screens/User";
import { Timers } from "./screens/Timers";
import { TimerEdit } from "./screens/TimerEdit";

function App() {
  return (
    <BrowserRouter>
      <div>
        <nav className="flex justify-center py-3 bg-pink-300">
          <ul className="flex space-x-2">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/logout">Logout</Link>
            </li>
          </ul>
        </nav>

        {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <PrivateRoute path="/user">
            <User />
          </PrivateRoute>
          <PrivateRoute path="/logout">
            <Logout />
          </PrivateRoute>
          <PrivateRoute path="/new">
            <TimerEdit />
          </PrivateRoute>
          <PrivateRoute path="/:id">
            <TimerEdit />
          </PrivateRoute>
          <PrivateRoute path="/">
            <Timers />
          </PrivateRoute>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
